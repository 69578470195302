import React, { useState } from 'react';
import { Menu } from 'antd';
import { RiSearchLine, RiCloseLine } from 'react-icons/ri';

import { CustomHighlighter } from 'components/molecules/highlighter';
import { SearchInput } from 'components/atoms';
import { Channel, Maybe } from 'generated/graphql';
import { DialerNumberCard } from 'components/molecules/cards';
import { parsePhoneNumber } from 'components/utils/phone-lib';
import * as S from './styles';

interface INumberSelectProps {
  channels?: Maybe<Channel>[];
  selectedChannel: any;
  handleNumberSelect: (data: any) => void;
}

const NumberSelect = ({ channels, selectedChannel, handleNumberSelect }: INumberSelectProps) => {
  const [showNumbersDropdown, setShowNumbersDropdown] = useState(false);
  const [searchInputVisible, setSearchInputVisible] = useState(false);
  const [numberList, setNumberList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const handleVisibleChange = () => setShowNumbersDropdown(prevState => !prevState);

  const handleToggleSearch = () => setSearchInputVisible(prevState => !prevState);

  const handleClose = () => {
    setShowNumbersDropdown(false);
  };

  const filterListObject = (query: string, list: any, keys: string[]) => {
    const lowSearch = query.toLowerCase();
    const filtered = list?.filter?.((item: any) =>
      keys?.some(key => String(item[key]).toLowerCase().includes(lowSearch)),
    );
    return filtered;
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchedKeyword = e.target.value;
    setSearchTerm(searchedKeyword);
    const filteredNumbers = filterListObject(searchedKeyword, channels, ['name', 'number']);
    setNumberList(filteredNumbers);
  };
  const channelList = searchTerm ? numberList : channels;

  const menuItemContent = (channel: Maybe<Channel>) => {
    const { number = '', name = '' } = channel || {};
    const { phoneFormatIntl, flagUrl, country } = parsePhoneNumber(number);
    return (
      <S.MenuItemWrapper className='flex items-center w-full'>
        <div className='flex items-center flex-1'>
          <img src={flagUrl} alt={country} className='mr-1.5' />
          <h6 className='text-gray-700 text-13 leading-3.25 font-semibold truncate w-30'>
            <CustomHighlighter
              textToHighlight={name}
              searchWords={[searchTerm]}
              className='line-clamp-1'
              wrapperClassName='w-30 truncate'
            />
          </h6>
        </div>
        <p className='text-gray-500 font-medium text-xs leading-4.5'>{phoneFormatIntl}</p>
      </S.MenuItemWrapper>
    );
  };

  const dropdownOptions = (
    <S.OptionListWrapper>
      <div className='flex py-3 px-4 shadow-bottom mb-0.5'>
        <RiCloseLine onClick={handleClose} className='cursor-pointer' size={18} color='#212121' />
        {searchInputVisible ? (
          <SearchInput
            className='search-field flex-1'
            placeholder='Search by name or number'
            size='large'
            onChange={handleSearch}
          />
        ) : (
          <p className='text-13 text-gray-700 leading-5 font-bold flex-1 text-center'>
            Calling Number
          </p>
        )}
        <RiSearchLine onClick={handleToggleSearch} size={18} color='#6E6681' />
      </div>
      {channelList?.length ? (
        <S.MenuWrapper onClick={handleNumberSelect} selectedKeys={[selectedChannel?.id]}>
          {channelList.map((channel, index) => (
            <>
              <Menu.Item
                key={channel?.id}
                onClick={() => setShowNumbersDropdown(false)}
                className={`${channel?.id === selectedChannel?.id ? 'bg-primary-50' : ''} `}
              >
                {/* <DialerNumberCard className='dialer-numbers-dropdown' channel={channel} /> */}
                {menuItemContent(channel)}
              </Menu.Item>
              {channelList?.length !== index + 1 && <Menu.Divider />}
            </>
          ))}
        </S.MenuWrapper>
      ) : (
        <S.MenuWrapper>
          <p className='p-10 text-center text-gray-500 font-heebo font-medium text-13'>No data</p>
        </S.MenuWrapper>
      )}
    </S.OptionListWrapper>
  );

  return (
    <>
      <S.DropdownWrapper
        data-cy='number-select'
        overlay={dropdownOptions}
        getPopupContainer={triggerNode => triggerNode}
        trigger={['click']}
        className='dialer-dropdown'
        visible={showNumbersDropdown}
        onVisibleChange={handleVisibleChange}
      >
        <div className='cursor-pointer'>
          <DialerNumberCard channel={selectedChannel} />
        </div>
      </S.DropdownWrapper>
    </>
  );
};
export default React.memo(NumberSelect);
