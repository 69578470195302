function ArrowSvgIcon({ className, style }: { className?: string; style?: React.CSSProperties }) {
  return (
    <svg
      style={style}
      className={className}
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.3352 7.845L6.16271 15.0175L4.98438 13.8392L12.156 6.66667H5.83521V5H15.0019V14.1667H13.3352V7.845V7.845Z'
        fill='currentColor'
      />
    </svg>
  );
}

export default ArrowSvgIcon;
