import { useMutation } from '@apollo/client';
import { TRIGGER_MIXPANEL } from 'graphql/channel/phoneWidget/mutation';

import { MixpanelOutboundCallPayload, MixpanelOutboundSmsPayload } from '../types';

export const useTriggerMixpanelEvent = () => {
  const [triggerMixpanelEvent, { loading, data, error }] = useMutation(TRIGGER_MIXPANEL);

  const trackOutboundActivityInMixpanel = async (
    payload: MixpanelOutboundSmsPayload | MixpanelOutboundCallPayload,
  ) => {
    await triggerMixpanelEvent({
      variables: { data: payload },
    });
  };

  return {
    trackOutboundActivityInMixpanel,
    loading,
  };
};
