import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from 'graphql/fragments/error';

export const REJECT_CONVERSATION = gql`
  mutation rejectConversation($data: RejectConversationInput!) {
    rejectConversation(data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const CALL_RECORDING = gql`
  mutation callRecording($data: RecordCallInput!) {
    callRecording(data: $data) {
      status
      data {
        status
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const CANCEL_OUTGOING_CALL = gql`
  mutation cancelOutgoingCall($data: OutgoingCancelInput!) {
    cancelOutgoingCall(data: $data) {
      status
      data {
        CancelStatus
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const CALL_HOLD = gql`
  mutation callHold($data: HoldCallInput!) {
    callHold(data: $data) {
      status
      data {
        message
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const CALL_TRANSFER = gql`
  mutation warmTransfer($data: WarmTransferInput) {
    warmTransfer(data: $data) {
      status
      data {
        conversationStatus
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const CALL_RATING = gql`
  mutation callRating($id: ShortId!, $data: CallRatingInputData!) {
    callRating(id: $id, data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const TRIGGER_MIXPANEL = gql`
  mutation triggerMixpanelEvent($data: MixpanelEvent!) {
    triggerMixpanelEvent(data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
