import { useMutation } from '@apollo/client';
import { ToastMessage } from 'components/atoms';
import { CHECK_CALL_MESSAGE_ELIGIBILITY } from 'graphql/foundation';

type EventType = 'Call' | 'Message';

export type EligibilityCheckPayload = {
  originationNumber: string;
  destinationNumber: string;
  eventType: EventType;
};

const useCallMessageEligibilityCheck = () => {
  const [checkEligibility] = useMutation(CHECK_CALL_MESSAGE_ELIGIBILITY);

  const checkCallMessageEligibility = async (payload: EligibilityCheckPayload) => {
    const { originationNumber, destinationNumber, eventType } = payload;
    const response = await checkEligibility({
      variables: {
        data: {
          conversationType: eventType,
          originationNumber,
          destinationNumber,
        },
      },
    });
    const { isEligible } = response?.data?.checkEventEligibility?.data || {};
    if (!isEligible) {
      ToastMessage({
        content: 'Insufficient Credit',
        type: 'danger',
      });
    }
    return !!isEligible;
  };

  return {
    checkCallMessageEligibility,
  };
};

export default useCallMessageEligibilityCheck;
