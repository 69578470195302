import { Avatar } from 'antd';

import { ManIcon } from 'components/atoms/icon';
import { CustomHighlighter } from 'components/molecules/highlighter';

import * as S from './Styles';

interface ContactAutoComplete {
  countryCode?: string;
  flagUrl?: string;
  number: string;
  searchedText: string;
  name: string;
  profilePicture: string;
}

export const ContactAutoCompleteOption = ({
  countryCode,
  flagUrl,
  number,
  searchedText,
  name,
  profilePicture,
}: ContactAutoComplete) => {
  return (
    <S.NumberAutoCompleteOption
      data-cy='contact-auto-complete'
      className='flex justify-between items-center gap-x-3.5 font-manrope'
    >
      {flagUrl && <img src={flagUrl} alt={countryCode || 'flag'} className='flag' />}
      <div className='w-48 truncate'>
        <div className='text-13 font-semibold text-gray-600 leading-5'>
          <CustomHighlighter
            textToHighlight={number}
            searchWords={[searchedText]}
            className='line-clamp-1'
            wrapperClassName='w-30 truncate'
          />
        </div>
        <div className='text-13 font-medium leading-5 text-gray'>
          <CustomHighlighter
            textToHighlight={name}
            searchWords={[searchedText]}
            className='line-clamp-1'
            wrapperClassName='w-30 truncate'
          />
        </div>
      </div>

      <Avatar
        size={24}
        className='avatar'
        shape='square'
        src={profilePicture}
        icon={<ManIcon id={number} />}
      />
    </S.NumberAutoCompleteOption>
  );
};
