/* eslint-disable no-restricted-globals */
import SecureLS from 'secure-ls';
import format from 'date-fns/format';

const ls = new SecureLS({ encodingType: 'aes', isCompression: false });

export const geUserTimeZone = () => {
  let tz = '';
  try {
    const data = ls.get('tz');
    tz = (data && JSON.parse(data)) || Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    console.warn(e);
  }
  return tz;
};
export function getDateOfTz(date: string | Date, timeZone: string) {
  if (typeof date === 'string') {
    return new Date(
      new Date(date).toLocaleString('en-US', {
        ...(timeZone && { timeZone }),
      }),
    );
  }

  return new Date(
    date.toLocaleString('en-US', {
      ...(timeZone && { timeZone }),
    }),
  );
}

export function getTimefromIso(ts: string) {
  // TODO should raise error
  if (ts) {
    const date = new Date(ts);
    const tz = geUserTimeZone();
    const dateWithTz = getDateOfTz(date, tz);
    return format(dateWithTz, 'HH:mm');
  }
  return '';
}

export function getTimeWithAmOrPm(ts: string) {
  // eg: 09:56 am
  if (ts) return format(new Date(ts), "h:mm aaa'");
  return '';
}

export function getDateInYearsMonthDay(ts: string) {
  // eg: Sep 01, 2022
  return format(new Date(ts), 'LLL dd, yyyy');
}

export function getTimeinms(ts: string) {
  return new Date(ts).getTime();
}

export function formatTimeToHour(ts: string) {
  return format(new Date(ts), 'HH:mm:ss');
}

export function timeAgo(dateString: string, showAgo = true) {
  // returns format eg: 1 mnth ago, 1h ago
  const seconds = Math.floor((new Date().valueOf() - new Date(dateString).valueOf()) / 1000);
  let interval = seconds / 31536000;
  if (interval > 1) {
    return `${Math.floor(interval)}y ${showAgo ? 'ago' : ''}`;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return `${Math.floor(interval)}mth ${showAgo ? 'ago' : ''}`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return `${Math.floor(interval)}d ${showAgo ? 'ago' : ''}`;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return `${Math.floor(interval)}h ${showAgo ? 'ago' : ''}`;
  }
  interval = seconds / 60;
  if (interval > 1) {
    return `${Math.floor(interval)}m ${showAgo ? 'ago' : ''}`;
  }
  return `a few seconds ${showAgo ? 'ago' : ''}`;
}

export function timeDurationFromNow(dateString: string) {
  // returns format eg: 1y, 1h 1m
  const seconds = Math.floor((new Date().valueOf() - new Date(dateString).valueOf()) / 1000);
  let value = seconds;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const units: { [key: string]: any } = {
    y: 24 * 60 * 60 * 30 * 12,
    w: 24 * 60 * 60 * 7,
    d: 24 * 60 * 60,
    h: 60 * 60,
    m: 60,
  };
  const resultArray: {
    unit: string;
    value: string | undefined | number;
  }[] = [];
  Object.keys(units).forEach(name => {
    const interval = Math.floor(value / units[name]);
    if (interval >= 1) {
      resultArray.push({
        unit: name,
        value: interval,
      });
    }
    value %= units[name];
  });
  let resultString = '';
  if (resultArray.length > 0) {
    resultArray.every((item, i) => {
      if (['y', 'w', 'd'].includes(item.unit)) {
        resultString = item.value + item.unit;
        return false;
      }
      resultString += `${i > 0 ? ' ' : ''}${item.value}${item.unit}`;
      return true;
    });
  } else {
    resultString = 'Just Now';
  }
  return resultString;
}

export function renderDate(dateStr: string) {
  const date = new Date(dateStr);
  const today = new Date();
  const yesterday = new Date();
  const currentYear = today.getFullYear();
  const year = date.getFullYear();
  const isDifferentYear = currentYear !== year;
  yesterday.setDate(today.getDate() - 1);
  if (date.toLocaleDateString() === today.toLocaleDateString()) {
    return 'Today';
  }
  if (date.toLocaleDateString() === yesterday.toLocaleDateString()) {
    return 'Yesterday';
  }
  return date.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'long',
    ...(isDifferentYear && { year: 'numeric' }),
  });
}

export function renderRelativeTimeDate(dateStr: string) {
  const date = dateStr ? new Date(dateStr) : new Date();
  const tz = geUserTimeZone();
  const dateWithTz = getDateOfTz(date, tz);
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  if (date.toLocaleDateString() === today.toLocaleDateString()) {
    return format(dateWithTz, 'p');
  }
  if (date.toLocaleDateString() === yesterday.toLocaleDateString()) {
    return 'Yesterday';
  }
  if (date.getFullYear() !== today.getFullYear()) {
    return format(date, 'LLL dd, yyyy');
  }
  return format(date, 'LLL dd');
}
export function dateWithWeekdays(ts: string) {
  /**
   * show Date in DD Month Year, weekday format
   * eg: 16 Mar 2021, Tue
   */
  return format(new Date(ts), 'dd LLL yyyy, E');
}
export function dateWithTime(ts: string) {
  /**
   * show Date in DD Month Year weekday format
   * eg: 16 Mar 2021 4:46pm
   */
  return format(new Date(ts), 'dd LLLL yyyy p');
}

export const secondsToHms = (secs: string) => {
  const hours = Math.floor(Number(secs) / (60 * 60));

  const divisorForMinutes = Number(secs) % (60 * 60);
  const minutes = Math.floor(divisorForMinutes / 60);

  const divisorForSeconds = divisorForMinutes % 60;
  const seconds = Math.floor(divisorForSeconds);

  const h = hours ? `${hours < 10 ? '0' : ''}${hours}: ` : '';
  const m = minutes ? `${minutes < 10 ? '0' : ''}${minutes}: ` : '00:';
  const s = seconds ? `${seconds < 10 ? '0' : ''}${seconds} ` : '00';

  return h + m + s;
};
export const remainingDaysFromNow = (endPeriod: string) => {
  if (!endPeriod) return 0;

  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const now: any = Date.now();
  const endDate: any = new Date(endPeriod);

  return endDate && Math.round(Math.abs((now - endDate) / oneDay));
};

export function isValidDate(d: any) {
  const date = new Date(d);
  return date && Object.prototype.toString.call(date) === '[object Date]';
}

export function formatDurationTimer(hours: number, minutes: number, seconds: number) {
  const hrStr = hours < 10 ? `0${hours}` : hours;
  const minStr = minutes < 10 ? `0${minutes}` : minutes;
  const secStr = seconds < 10 ? `0${seconds}` : seconds;
  const callDurationTime = `${hrStr}:${minStr}:${secStr}`;
  return callDurationTime;
}

const isDateAfterToday = (date: any) => {
  return new Date(date.toDateString()) > new Date(new Date().toDateString());
};

export const disableDateAfterToday = (current: any) => {
  // Can not select days before today
  return current && isDateAfterToday(current);
};
