import { useEffect, useRef, useMemo } from 'react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import jwtDecode from 'jwt-decode';

import { webRtcPeerConnectionEnabled } from 'services/apollo/reactiveVars';
import useLocalStorage from 'hooks/useLocalStorage';
import { WORKSPACES } from 'graphql/foundation';

export const useLoggedInMember = () => {
  const [user] = useLocalStorage('user', '');
  const [tokens] = useLocalStorage('_tokens', '');
  const accessToken = tokens?.accessToken;
  const [loadWorkspaces, { data: workspaceData }] = useLazyQuery(WORKSPACES);
  // const { defaultPaymentCard } = useDefaultCardQuery();
  // const { brand, email } = defaultPaymentCard || {};
  // const isPaypalDefaultCard = !brand && !!email;
  const isPaypalDefaultCard = false; // we currently don't have paypal integration in our payment
  const webRtcPeerConnection = useReactiveVar(webRtcPeerConnectionEnabled);
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (mounted.current) {
      loadWorkspaces();
    }
  }, [loadWorkspaces]);

  const activeWorkspaceId = sessionStorage.getItem('_activeWorkspaceId');
  const workspaces = workspaceData?.workspaces?.data ?? user?.details?.workspaces;
  const loggedInMemberInfo = workspaces?.find((item: any) => item.id === activeWorkspaceId) || {};

  const {
    memberId,
    plan,
    twilioStatus,
    reviewStatus,
    numberSubscription,
    kycVerified,
    nonVoipSubscriptionStatus,
  } = loggedInMemberInfo || {};
  const { remainingDays, subscriptionActive, remainingGracePeriod } = plan || {};
  const loggedInMemberId = memberId;
  const planRemainingDays = remainingDays;
  const isEmailVerifiedUser = user?.details?.userProfile?.status === 'Verified';
  const userEmail = user?.details?.userProfile?.email;
  const username = `${user?.details?.userProfile?.firstname} ${user?.details?.userProfile?.lastname}`;
  const isUserSuspended = twilioStatus === 'Suspended';
  const isSubscriptionCancelled = subscriptionActive === 'Inactive';
  const userAuthId = user?.details?.id;
  const isExpired = subscriptionActive === 'Expired';
  const isDeletedWorkspace = workspaces?.some(
    (workspace: any) => workspace.id === activeWorkspaceId && workspace.status === 'Inactive',
  );
  const isSubscriptionActive = subscriptionActive === 'Active';
  const isUnderReview = reviewStatus === 'Under Review';
  const isSubscriptionExpired = isExpired;
  const isOnGracePeriod = !isSubscriptionExpired && planRemainingDays <= 0;

  const numberSubscriptionStatus = numberSubscription || {};
  const isNumberSubscriptionExpired = numberSubscriptionStatus.subscriptionStatus === 'Expired';
  const isAutoRenewFailed = numberSubscriptionStatus.autoRenew === 'Failed';
  const isKYCVerified = kycVerified ?? true;
  const isNonVoipSubscriptionExpired = nonVoipSubscriptionStatus?.subscriptionStatus === 'Expired';

  const isAlertBarVisible = useMemo(
    () =>
      !isKYCVerified ||
      isUserSuspended ||
      !webRtcPeerConnection ||
      isNumberSubscriptionExpired ||
      isAutoRenewFailed ||
      isSubscriptionExpired ||
      isOnGracePeriod ||
      isPaypalDefaultCard ||
      isNonVoipSubscriptionExpired,
    [
      isKYCVerified,
      isUserSuspended,
      webRtcPeerConnection,
      isNumberSubscriptionExpired,
      isAutoRenewFailed,
      isSubscriptionExpired,
      isOnGracePeriod,
      isPaypalDefaultCard,
      isNonVoipSubscriptionExpired,
    ],
  );

  const accessTokenDecoded: any = useMemo(() => (accessToken ? jwtDecode(accessToken) : {}), [
    accessToken,
  ]);

  const userRole: any = accessTokenDecoded?.user_claims?.role;

  return useMemo(
    () => ({
      accessToken,
      activeWorkspaceId,
      loggedInMemberInfo,
      loggedInMemberId,
      planRemainingDays,
      isAlertBarVisible,
      isSubscriptionCancelled,
      userAuthId,
      isSubscriptionExpired,
      isDeletedWorkspace,
      isUserSuspended,
      isEmailVerifiedUser,
      isUnderReview,
      userEmail,
      numberSubscription: numberSubscriptionStatus,
      isAutoRenewFailed,
      isNumberSubscriptionExpired,
      isKYCVerified,
      username,
      userRole,
      isOnGracePeriod,
      remainingGracePeriod,
      isPaypalDefaultCard,
      user,
      tokens,
      isNonVoipSubscriptionExpired,
      isSubscriptionActive,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeWorkspaceId, loggedInMemberInfo, user, tokens],
  );
};
