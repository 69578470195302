import { createContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useLocalStorage from 'hooks/useLocalStorage';

type ILoginContext = {
  loginPayload?: any;
  setLoginPayload?: (val: any) => void;
};

const LoginContext = createContext<ILoginContext>({});

function LoginProvider({ children }: any) {
  // save login payload in local storage temporarily for login
  const [loginPayload, setLoginPayload] = useLocalStorage('auth_login', '');

  const { pathname, state, search } = useLocation();
  const { from }: any = state || {};

  useEffect(() => {
    if (from) {
      localStorage.setItem('redirectUrl', from + search);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoginContext.Provider
      value={{
        loginPayload,
        setLoginPayload,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
}

export { LoginContext, LoginProvider };
