import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from 'graphql/fragments/error';
import { AGENT_NODE, NUMBER_ABILITIES_NODE, NUMBER_CHECKOUT_PRICE } from '../fragments';

export const MY_NUMBERS = gql`
  query numbers {
    numbers {
      status
      data {
        id
        name
        number
        agents {
          ...AgentFields
        }
        callStrategy
        callForward
        numberCheckoutPrice {
          ...NumberCheckoutPriceFields
        }
        call
        sms
        mms
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${AGENT_NODE}
  ${NUMBER_CHECKOUT_PRICE}
`;
export const MY_VOIP_NONVOIP_NUMBERS = gql`
  query numbers($numberTypes: [PhoneNumberType]) {
    numbers(numberTypes: $numberTypes) {
      status
      data {
        id
        name
        number
        phoneNumberType
        agents {
          ...AgentFields
        }
        callStrategy
        callForward
        numberCheckoutPrice {
          ...NumberCheckoutPriceFields
        }
        call
        sms
        mms
        nonVoipSubscriptionStatus
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${AGENT_NODE}
  ${NUMBER_CHECKOUT_PRICE}
`;
export const NUMBER_SETTINGS = gql`
  query numberSettings($channel: ShortId!) {
    numberSettings(channel: $channel) {
      status
      data {
        id
        name
        autoRecordCalls
        internationalCallAndMessages
        emailNotification
        incomingCallStrategy
        incomingCallForward
        unanswerCallsFallback
        externalNumber
        simultaneousDialing
        transcription
        agents {
          ...AgentFields
        }
        abilities {
          ...NumberAbilitiesFields
        }
        shared
        smsForward
        smsForwardedNumber
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${AGENT_NODE}
  ${NUMBER_ABILITIES_NODE}
`;

export const FETCH_IVR_SETTINGS = gql`
  query ivrSettings($channel: ShortId!) {
    ivrSettings(channel: $channel) {
      status
      data {
        source
        voice
        accent
        recordingType
        recordingUrl
        ivrOptions {
          extensionNumber
          forwardTo
          unansweredForwardTo
        }
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const FETCH_IVR_SETTINGS_NEW = gql`
  query multilevelIvrSettings($channel: ShortId!) {
    multilevelIvrSettings(channel: $channel) {
      status
      error {
        code
        message
      }
      data {
        id
        root {
          messageType
          recording {
            ttsUrl
            customUrl
          }
          message {
            text
            voice
            accent
          }
        }
        children {
          ...ivrChildren
        }
      }
    }
  }

  fragment ivrChildren on MultilevelIvrChildData {
    ...ivrChildDetails
    children {
      ...ivrChildDetails
      children {
        ...ivrChildDetails
        children {
          ...ivrChildDetails
        }
      }
    }
  }
  fragment ivrChildDetails on MultilevelIvrChildData {
    extensionNumber
    actionType
    messageType
    recording {
      ttsUrl
      customUrl
    }
    message {
      text
      voice
      accent
    }
    forwardingDetails {
      forwardTo
      unanswerAction
    }
  }
`;

export const FETCH_CALLERID_SETTINGS = gql`
  query callerIdSettingsData($channel: ShortId!) {
    callerIdSettingsData(channel: $channel) {
      status
      data {
        status
        action
        callerId
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const FETCH_VERIFIED_CALLER_IDS = gql`
  query verifiedCallerIds {
    verifiedCallerIds {
      status
      data {
        id
        number
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const A2P_WORKSPACE_CAMPAIGN = gql`
  query workspaceCampaign {
    workspaceCampaign {
      status
      data {
        campaignSid
        rate
        useCase
        createdAt
        createdBy
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const PRORATE_NUMBER_CHARGE = gql`
  query prorateNumberCharge($channel: ShortId!) {
    prorateNumberCharge(channel: $channel) {
      status
      data {
        totalCharge
        remainingProrateCharge
        prorateCharge
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const FETCH_SIP_TRUNKING_SETTINGS = gql`
  query sipTrunkingSettings($channel: ShortId!) {
    sipTrunkingSettings(channel: $channel) {
      status
      data {
        domainName
        username
        password
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
